import { useDrag } from "@use-gesture/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { TbArrowNarrowLeft, TbCheck } from "react-icons/tb";
import InputMask from "react-input-mask";
import BarLoader from "react-spinners/BarLoader";
import useCertificate from "../hooks/useCertificate";
import { useValidation } from "../hooks/useValidation";
import css from "./FormWrapperV2.module.css";



export default function FormWrapperV2({ cn }) {
  const { info, saveInfo } = useCertificate();
  const { validate, hasErrors } = useValidation();

  const [data, setData] = useState({
    name: '',
    phone: '',
    clientRules: false,
  })
  const [validationResult, setValidationResult] = useState({
    name: "",
    phone: "",
    clientRules: false,
  });

  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const windowWidth = window.screen.availWidth;

  const resetValidation = () => {
    setValidationResult({ name: "", phone: "", clientRules: false });
  }

  const resetData = () => {
    setData({
      name: '',
      phone: '',
      clientRules: false,
    });
  }



  const handleDataChange = (field, e) => {
    resetValidation()

    if (field === 'clientRules') {
      setData((prev) => ({ ...prev, [field]: !prev[field] }));
      return;
    }

    setData((prev) => ({ ...prev, [field]: e.target.value }));
  }

  const handleCancel = () => {
    saveInfo((prev) => ({ ...prev, check: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validatedName = validate("name", data.name);
    const validatedPhone = validate("phone", data.phone);
    const validatedClientRules = validate("clientRules", data.clientRules);

    setValidationResult({
      name: validatedName,
      phone: validatedPhone,
      clientRules: validatedClientRules,
    });

    if (hasErrors([validatedName, validatedPhone, validatedClientRules])) {
      return;
    }

    try {
      setError(null);
      setIsLoading(true);

      const sendData = {
        amount: info.price,
        orderDescription: info.title,
        orderId: `${info.id}_${new Date().getTime()}`,
        name: data.name,
        phone: data.phone.replaceAll(" ", ""),
      };

      const response = await axios.post(
        "https://bodyfactory-backend.vercel.app/api/v1/payments",
        sendData
      );

      if ("url" in response.data) {
        const url = response.data.url;

        localStorage.setItem("user_order", JSON.stringify(sendData));

        window.open(url, "_self");
      } else {
        setError(error);
      }
    } catch (error) {
      setError(error);
      return error;
    } finally {
      setIsLoading(false);
      resetData();
      resetValidation();
    }

    handleCancel();
  }

  useEffect(() => {
    const checkAllFieldsAreFilled = () => {
      if (data.name && data.phone && data.clientRules) {
        setIsDisabled(false);
        return
      }

      setIsDisabled(true);
    }

    checkAllFieldsAreFilled();
  }, [data])


  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (!active && Math.abs(mx) > 10 && xDir < 0) {
        saveInfo((prev) => ({ ...prev, check: false }));
        cancel();
      }
    }
  );

  const renderCertificateInfo = () => {
    return (
      <form className={css.wrapper} onSubmit={handleSubmit}>
        <div className={css.name}>
          <div className={css.input}>
            <input
              type="text"
              name="clientName"
              value={data.name}
              className={
                !!validationResult["name"]
                  ? `${css.userFormInput} ${css.inputError}`
                  : css.userFormInput
              }
              autoComplete="off"
              required
              placeholder="Ваше ім'я"
              onChange={(e) => handleDataChange('name', e)}
            />
          </div>
          <div className={css.note}>
            {!!validationResult["name"] ? (
              <p className={css.errorMessage}>{validationResult["name"]}</p>
            ): (
              <p className={css.coverMessage}>Ім'я особи, яка здійснює платіж</p>
            )}
          </div>
        </div>
        <div className={css.phone}>
          <div className={css.inputPhone}>
            <InputMask
              type="tel"
              name="clientPhone"
              value={data.phone}
              onChange={(e) => handleDataChange('phone', e)}
              className={
                !!validationResult["phone"]
                  ? `${css.userFormInput} ${css.inputError}`
                  : css.userFormInput
              }
              required
              mask="+380\ 99 999 99 99"
              maskChar=" "
              alwaysShowMask
            />
          </div>
          <div className={css.errorsPhone}>
            {!!validationResult["phone"] ? (
              <p className={css.errorMessage}>
                {validationResult["phone"]}
              </p>
            ) : (
              <p className={css.coverMessage}>Телефон особи, яка здійснює платіж</p>
            )}
          </div>
        </div>
        <div className={css.posluga}>
          <h4 className={css.coverTitle}>{info.title}</h4>
          <span className={css.coverSubTitlePrice}>{info.price} UAH</span>
        </div>
        <div className={css.cancel}>
          <button
            className={`${css.userFormButton} ${css.userFormButtonCancel}`}
            type="button"
            onClick={handleCancel}
          >
            <TbArrowNarrowLeft className={css.coverButtonIcon} />
            Скасувати
          </button>
        </div>
        <div className={css.submit}>
          <div className={css.userAgreement}>
            <input
              type="checkbox"
              id="politika"
              onChange={() => handleDataChange('clientRules')}
              checked={data.clientRules}
              className={css.userAgreementCheckbox}
              style={{
                color: !!validationResult.clientRules ? "red" : "initial",
              }}
            />
            <label htmlFor="politika" className={css.userAgreementLabel}>
              Приймаю <a href="/user-agreement.html" target='_blank' className={css.userAgreementLink}>угоду користувача</a>
            </label>
          </div>
          <div className={css.button}>
            <button
              className={`${css.submitButton} ${css.userFormButton} ${css.userFormButtonPay}`}
              type="submit"
              disabled={isLoading || isDisabled}
            >
              {isLoading ? (
                <BarLoader
                  color="#f8bf00"
                  className={css.coverButtonIcon}
                />
              ) : (
                <>
                  <TbCheck className={css.coverButtonIcon} style={{ visibility: isDisabled ? 'hidden' : 'initial' }} /> Придбати
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className={info.check ? `${css.cover} ${css.coverActive}` : css.cover}>
      <div {...bind()} className={`${cn} ${css.backImage}`} style={{
        backgroundImage: `url(${windowWidth < 768 ? "/images/cover-4.png" : "/images/coverw.png"})`,
      }}>
        <img
          src={windowWidth < 768 ? "/images/cover-4.png" : "/images/coverw.png"}
          alt="BF"
          className={css.coverImg}
          style={{ visibility: 'hidden' }}
        />
        {info.check && renderCertificateInfo()}
      </div>
    </div>
  )
}
