import React, { useEffect, useState } from 'react';
import { config } from 'react-spring';
import css from './Certificates.module.css';
import cssForm from './FormWrapper/FormWrapper.module.css';
import FormWrapperV2 from './FormWrapper/FormWrapperV2';
import VerticalCarousel from './VerticalCarousel/VerticalCarousel';
// import cards from "./DataBase/certificate";
import useCertificate from './hooks/useCertificate';

const Certificates = () => {
  const { info } = useCertificate();

  const [cards, setCards] = useState([]);
  const [misc, setMisc] = useState({});

  useEffect(() => {
    fetch('./data/data.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setCards(myJson);
      });

    fetch('./data/data_misc.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setMisc(myJson);
      });
  }, []);

  return (
    <section className={css.container}>
      <div className={css.titleWrapper}>
        <h1 className={css.title}>{misc.title}</h1>
      </div>
      <div className={css.AppVertical}>
        <VerticalCarousel
          cards={cards}
          offsetRadius={2}
          showNavigation={true}
          animationConfig={config.gentle}
        />

        {/* <FormWrapper
          cn={
            info.check
              ? `${cssForm.formWrapper} ${cssForm.checked}`
              : cssForm.formWrapper
          }
        /> */}

        <FormWrapperV2
          cn={info.check ? `${cssForm.formWrapper} ${cssForm.checked}` : cssForm.formWrapper}
        />
      </div>
    </section>
  );
};

export default Certificates;
